import * as React from "react";
import "./History.css";
import {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';

const HistElement = (props) => {
    const {type, phone, time, copy, call} = props;
    const [show, setShow] = useState(false);
    const [posPop, setPos] = useState({left:0,top:0});
    const ref = useRef();
    const history = useHistory();
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });
    const handleClickOutside = (event: Event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setTimeout(function() { //Start the timer
                setShow(false); //After 1 second, set render to true
            }, 100)
        }
    };
    return (
        <>
            <div className="lines" ref={ref}>
                <div className="frame">
                    <div className="x24">
                        {"outbound" === type?
                        <PhoneForwardedIcon className="icon" style={{fill: "var(--blue50)"}}/>:"inbound" === type?<PhoneCallbackIcon className={"icon"} style={{fill: "var(--caribbean-green)"}}/>:<PhoneMissedIcon className={"icon"} style={{fill: "var(--red60)"}}/>
                        }
                    </div>
                </div>
                <div className="frame-1998">
                    <div className="dbody3">{phone}</div>
                </div>
                <div className="frame-1999">
                    <div className="text dbody3">{time}</div>
                </div>
                <div className="frame">
                    <img
                        className="x24"
                        src={"https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61fbb56136ac1d7db4d9d2de/img/24-3@2x.svg"}
                        onClick={(e)=>{
                            let pos = e.target.getBoundingClientRect();
                            setPos({left:pos.y, top:pos.top+35});
                            setShow(!show);}
                        }
                    />
                </div>
            </div>
            {show? <div className="more_popup_menu border-1px-black95" style={{top:posPop.top}}>
                    <div className="popup-content clickable" onClick={() => {
                        call(phone);
                    }}>
                        <img className="x24-10"
                             src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61fbb56136ac1d7db4d9d2de/img/24-12@2x.svg"/>
                        <div className="value dbody-2">Call Back</div>
                    </div>
                    <div className="popup-content clickable" onClick={() => {
                        copy(phone);
                        }}>
                        <img className="x24-13"
                             src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61fbb56136ac1d7db4d9d2de/img/24-15@2x.svg"/>
                        <div className="value dbody-2">Copy phone number</div>
                    </div>
                </div>:<></>}
        </>
    );
};

export default HistElement;