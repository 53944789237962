import {createSlice} from '@reduxjs/toolkit';

const userInfoSlice = createSlice({
        name: 'user',
        initialState: { userInfos: JSON.parse(sessionStorage.getItem('userdata')) || {first_name: "", last_name: "", user_email: "", uuid: "", domain: "", domain_uuid: "", extension_uuid:"", extension:""}},
        reducers: {
            reset(state,action){
                sessionStorage.removeItem('userdata')
                state.userInfos = {first_name: "", last_name: "", user_email: "", uuid: "", domain: "", domain_uuid: "", extension_uuid:"", extension:""};
            },
            update(state,action) {
                let infos = state.userInfos;
                let data = action.payload;
                sessionStorage.setItem("userdata", JSON.stringify({...infos, ...data}));
                state.userInfos = {...infos, ...data};
            }
        }
    }
);

export const userActions = userInfoSlice.actions;

export default userInfoSlice;