import AppRouter from "../src/component/Router";
import "./styleguide.css";
import "./index.css";
import './App.css';
import {createContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {getAuthClient} from "./utils/auth";
import socketIOClient from "socket.io-client";
import {useSelector} from "react-redux";

export const SocketContext = createContext();

function App() {
    const auth = getAuthClient();
    const [global_socket, setSocket] = useState();
    const userData = useSelector(state => state.user.userInfos);
    useEffect(async () => {
        const profileData = {
            domain: sessionStorage.getItem('domain'),
            ext: sessionStorage.getItem('ext'),
            pass: sessionStorage.getItem('pass')
        }
        //Webphone.init(profileData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        let socket
        try{
            if(userData.uuid!== ""){
                let baseUrl = new URL(process.env.REACT_APP_CRM_BASE);
                socket = socketIOClient(`${baseUrl.protocol}${baseUrl.hostname}`);
                setSocket(socket)
                socket.on('connect',function(){
                    auth.isLoggedIn().then((res) => {
                        if(userData){
                            socket.emit('ehlo', {uuid: userData?.uuid, domain:userData?.domain_uuid});
                        }
                    })
                });
            }
            socket.on("CHANNEL_RINGING", data => {
                console.log(data)
            });
            socket.on("CHANNEL_HANGUP", data => {
                console.log(data)
            });
            socket.on("CHANNEL_HANGUP_voicemail", data => {
                console.log(data)
            });
            if (!("Notification" in window)) {
                console.log("Browser does not support desktop notification");
            } else {
                Notification.requestPermission();
            }
        }
        catch (e) {
            console.log(e)
        }

    }, [userData]);
  return (
      <>
          <Helmet>
              <meta name="viewport" content="width=device-width,initial-scale=1"/>
              <meta http-equiv="ScreenOrientation" content="autoRotate:disabled"/>
          </Helmet>
          <SocketContext.Provider value={global_socket}>
          <AppRouter/>
          </SocketContext.Provider>
      </>
  );
}

export default App;
