import PhoneInput from "../../ui/PhoneInput";
import React, {useEffect, useState} from "react";
import NativeSelect from '@mui/material/NativeSelect';
import DialpadGen from "./DialpadGen";
import {getAuthClient} from "../../../utils/auth";
import {useDispatch, useSelector} from "react-redux";
import {webphoneActions} from "../../../store/webphone-state-slice";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export default function IdleScreen({number, handleInputChange, clean, updateNumber, session, startCall, eraseNumber}){
    let [currDest, setCurrDest] = useState(sessionStorage.getItem("destination")||false);
    const dispatch = useDispatch();
    let auth = getAuthClient();
    const phoneListState                = useSelector(state => state.webphone.phoneNumbers);
    let [localPhoneList, setLocalList] = useState([])
    useEffect(()=>{
        if(!(phoneListState??[]).includes(currDest)){
            let temp = [...localPhoneList,...(phoneListState??[])];
            temp.push(currDest)
            dispatch(webphoneActions.updatePhones(temp))
            setLocalList(temp)
        }
    },[phoneListState])

    useEffect(()=>{
        sessionStorage.setItem("destination", currDest)
    },[currDest]);

    function modifyPhone(newPhone){
        let extDetail = JSON.parse(sessionStorage.getItem("extension_full"));
        let updatePhone = {outbound_caller_id_name:newPhone, outbound_caller_id_number:newPhone};
        const post_data = {...extDetail, ...updatePhone};
        const responce = auth.changePhone(post_data)
            .then(
                function (response) {
                    if (responce.error == '' || !responce.error) {
                        setCurrDest(newPhone);
                    }
                    else{
                        alert("Error occured while changing the outgoing phone number")
                    }
                    return response;
                }
            )
            .catch((error) => {
                console.log('Login error', error);
            });
    }
    return(
        <>
            <PhoneInput label={""} name={"number"} value={number} defaultValue={number} onChange={handleInputChange} clean={clean} classInput={"borderless-input dbody3 number"} classLabel={"col-start-flex dbody3 phone-input"}/>
            <div className="divider"/>
            <DialpadGen session={session} updateNumber={updateNumber}/>
            {currDest?
                <div className="current_number">
                    <img
                        className="mask-group"
                        src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61e937773964d2281c0590df/img/mask-group@2x.svg"
                    />
                    <div className="phone dbody3">
                        {
                            phoneListState?.length??0 > 0?
                                <NativeSelect
                                    value={currDest}
                                    inputProps={{
                                        name: 'phone',
                                        id: 'uncontrolled-native',
                                    }}
                                    onChange={(e) => {
                                        modifyPhone(e.target.value);
                                    }
                                    }
                        >
                            {phoneListState.map((phone) => {
                                return(
                                    <option value={phone}>{phone}</option>
                                )
                            })}
                        </NativeSelect>:
                                <>{currDest}</>
                        }
                    </div>
                </div>:
                <></>
            }
            <div className="flex-row">
                <div/>
                <div className="btn" onClick={()=>{startCall()}}>
                    <LocalPhoneIcon
                        className={"answer"}
                    />
                </div>
                <div className="btn_delete" onClick={eraseNumber}>
                    <img
                        src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61e937773964d2281c0590df/img/24-3@2x.svg"
                    />
                </div>
            </div>
        </>
    )
}