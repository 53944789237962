import React from 'react';

const Input = React.forwardRef((props, ref) => {
    const { label,name,value,onChange, classInput="",classLabel="", symbol=""} = props;
    return (
        <label
            className={classLabel}>
            {label}
            {"" != symbol?
            <div className="input-container">
                <input
                className={classInput}
                name={name}
                type="text"
                value={value}
                autoComplete={true}
                onChange={onChange}>
                </input>
            </div>:
                <input
                    className={classInput}
                    name={name}
                    type="text"
                    value={value}
                    autoComplete={true}
                    onChange={onChange}>
                </input>
            }
        </label>
    );
});

export default Input;

