import {createSlice} from '@reduxjs/toolkit';

const webphoneStateSlice = createSlice({
        name: 'webphone',
        initialState: { extensions: JSON.parse(sessionStorage.getItem('extensions')) || [],
            phoneNumbers: JSON.parse(sessionStorage.getItem('destinations')) || [],
            menuState: JSON.parse(sessionStorage.getItem('sideMenuState')) || undefined,
            menuCollapsed: false,
            manage_user: JSON.parse(sessionStorage.getItem("crm_users")) || [],
            leads: JSON.parse(sessionStorage.getItem("leads")) || [],
        },
        reducers: {
            updateExtensions(state,action) {
                sessionStorage.setItem("extensions", JSON.stringify(action.payload));
                state.extensions = action.payload;
            },
            updatePhones(state,action) {
                sessionStorage.setItem("destinations", JSON.stringify(action.payload));
                state.phoneNumbers = action.payload;
            },
            updateMenuState(state,action) {
                sessionStorage.setItem("sideMenuState", JSON.stringify(action.payload));
                state.menuState = action.payload;
            },
            updateMenuCollapsed(state,action) {
                sessionStorage.setItem('menuCollapsed',action.payload)
                state.menuCollapsed= action.payload;
            },
            updateUsers(state,action) {
                sessionStorage.setItem("crm_users",JSON.stringify(action.payload))
                state.manage_user = action.payload;
            },
            updateLeads(state,action) {
                sessionStorage.setItem("leads",JSON.stringify(action.payload))
                state.manage_user = action.payload;
            },
        }
    }
);

export const webphoneActions = webphoneStateSlice.actions;

export default webphoneStateSlice;