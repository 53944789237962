import "./Settings.css"
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import Webphone from "../../../Webphone";
import Switch from "../../ui/Switch";
const Settings = (props) => {
    const{unavailable, setStatus} = props;
    const history = useHistory();
    const clearState = () => {
        sessionStorage.clear();
        history.push("/");
        Webphone.newUa.unregister();
        Webphone.newUa.stop();
        Webphone.newUa = null;
    }

    return(
        <div className="webphone-settings screen">
            <h1 className="title dh2">Settings</h1>
            <div className="setting-panel">
                <div className="settings-opt">
                    <div className="settings-icons">
                        <img
                            src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61f2c9aa1c2fccc3a9515929/img/24-6@2x.svg"
                        />
                    </div>
                    <div className="settings-icons-sub">
                        <div className="dbody3">Do not disturb</div>
                        <div className="dbody2">Unavailable for incoming calls
                        </div>
                    </div>
                    <Switch
                            isOn={unavailable}
                            handleToggle={() => {
                                setStatus(unavailable)
                            }}
                        />
                </div>
                <div className="settings-opt" onClick={clearState}>
                    <div className="settings-icons">
                        <img
                            src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61f2c9aa1c2fccc3a9515929/img/24-3@2x.svg"
                        />
                    </div>
                    <div className="settings-icons">
                        <div className="dbody3">Logout</div>
                    </div>
                </div>
            </div>
        </div>)
}

export default Settings;