import React, {useState} from "react";
import cross from "../../assets/cross.png"

const PhoneInput = React.forwardRef((props, ref) => {
    const { label,name,value,onChange, clean, classInput="",classLabel="", symbol=""} = props;

    return (
        <div
            className={classLabel}>
            {label}
            {"" != symbol?
                <div className="input-container">
                    <input
                        className={classInput}
                        name={name}
                        type="tel"
                        value={value}
                        autoComplete={true}
                        onChange={onChange}>
                    </input>
                </div>:
                <input
                    className={classInput}
                    name={name}
                    type="tel"
                    value={value}
                    autoComplete={true}
                    onChange={onChange}>
                </input>
            }
            <>{value.length > 0 ?
                <img src={cross} onClick={() => {
                    clean()
                }}/>:<></>
            }</>
        </div>
    );
});

export default PhoneInput;