import React, {useState} from "react";
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import DialpadOutlinedIcon from '@mui/icons-material/DialpadOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export default function OnCallScreen({number, connected, log, timeState, onCall, inco, hold, showKeysFunc, showTransFunc, mute, startCall, endCall, session}){
    const dialMenuList = [{act: hold, name:'HOLD', img: PauseOutlinedIcon},
        {act: showKeysFunc, name:'KEYPAD', img: DialpadOutlinedIcon},
        {act: ()=>showTransFunc("ADD"), name:'ADD', img:AddBoxOutlinedIcon},
        {act: () => {}, name:'LEAD', img: PeopleAltOutlinedIcon},
        {act: showTransFunc, name:'TRANSFER', img: FastForwardOutlinedIcon},
        {act: mute, name:'MUTE', img: MicOffOutlinedIcon},
    ]
    const [dialOpt, setDialOpt] = useState(false);
    return(
        <>
            <div className="avatar border-1px-black80">
                <img
                    className="avatar-icon"
                    src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61efaf0bdd0b21a3ff5d44c6/img/group-2069@2x.svg"/>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
                <div className="number long">{number}</div>
                <>{!connected?<></>:<div className="chrono dbody3">{timeState.toString().toHMS()}</div>}</>
                <div className="call-status calling dbody3">{log}</div>
            </div>
                <>{inco && false == onCall?<div className="call_menu"></div>:
                    <div className="call_menu overlap-group-container">
                        {Object.values(dialMenuList).map((key) => {
                            let selected = "";
                            if(dialOpt == key.name && (dialOpt === "HOLD" || dialOpt === "MUTE")) {
                                selected = " selected";
                            }
                            let CustomIcon = key.img
                            return (
                                <>
                                    <div className={"overlap-group zoomEffect"+selected} onClick={()=>{
                                        key.act();
                                        if(dialOpt === key.name){
                                            setDialOpt(false)
                                        }
                                        else{
                                            setDialOpt(key.name);
                                        }}}>
                                        <CustomIcon className="filter-gray"/>
                                        <div className="keypad dbody3">{key.name}</div>
                                    </div>
                                </>
                            )})}
                    </div>}</>
            <>
                {false == onCall?
                    <div className={"incoMenu"}>
                        <div className="btn" onClick={()=>{startCall()}}>
                            <LocalPhoneIcon className="answer"/>
                        </div>
                        {inco?
                            <div className="btn hang-color" onClick={()=>{
                                console.log("NotDial")
                                endCall(session)}}>
                                <LocalPhoneIcon className="answer" style={{
                                    transform: "rotate(134deg)"}}/>
                            </div>:<></>}
                    </div>
                    :
                    <div className="btn hang-color" onClick={()=>{
                        console.log("NotDial")
                        endCall(session)}}>
                        <LocalPhoneIcon className="answer" style={{
                            transform: "rotate(134deg)"}}/>
                    </div>
                }
            </>
        </>
    )
}