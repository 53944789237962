import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import VolumeUp from '@mui/icons-material/VolumeUp';

export default function VolumeSlider(props) {
    let {val} = props;
    const [value, setValue] = React.useState(val.volume*100);

    const handleChange = (event, newValue) => {
        val.volume = newValue/100;
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "-webkit-fill-available", background: "var(--blackmain)", borderRadius: "7px", padding: "2% 5%"}}>
            <Stack spacing={2} direction="row" sx={{'& > svg': { fill:"white"}}} alignItems="center">
                <VolumeUpOutlinedIcon />
                <Slider aria-label="Volume" value={value} onChange={handleChange}/>
                <span style={{color:"white", fontFamily:"'Poppins"}}>{value}%</span>
            </Stack>
        </Box>
    );
}
