import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import "./dialpad.css";
import Input from "../../ui/Input";
import JsSIP from "jssip";
import PhoneInput from "../../ui/PhoneInput";
import hide from "./hide.png"
import Webphone from "../../../Webphone";
import History from "../History";
import Settings from "../Settings";
import BottomMenu from "../../BottomMenu";
import Contacts from "../Contacts";
import {getAuthClient} from "../../../utils/auth";
import TransferCall from "../TransferCall";
import VolumeSlider from "../../ui/VolumeSlider";
import MicSlider from "../../ui/MicSlider";
import TopBarStatus from "../../sub-components/TopBarStatus";
import IdleScreen from "./IdleScreen";
import DialpadGen from "./DialpadGen";
import OnCallScreenDial from "./OnCallScreenDial";
import OnCallScreen from "./OnCallScreen";
import {useDispatch, useSelector} from "react-redux";
import {webphoneActions} from "../../../store/webphone-state-slice";
import {DtmfPlayer} from "play-dtmf";
import {userActions} from "../../../store/user-info-slice";
import DialpadIcon from '@mui/icons-material/Dialpad';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactsIcon from '@mui/icons-material/Contacts';
const Dialpad = (props) => {
    const {
        menu,
        audioExt
    } = props;
    const defaultCallStatus = {
        extension: "",
        uuid:"",
        timestamp: undefined,
        dest: "",
        direction: "Idle",
        state: "",

    }
    let history = useHistory();
    const domain = sessionStorage.getItem('domain');
    const ext = sessionStorage.getItem('ext');
    const [unavailable, setStatus] = useState(false);
    const [micPermission, setMicPerm] = useState(false);
    const [newUa,setUa] = useState(false);
    const [number, setNumber] = useState("");
    const [showKeys, setShowKeys] = useState(false);
    const [showTrans, setShowTrans] = useState(false);
    const [transMod, setTransMod] = useState("Transform");
    const [muted, setMuted] = useState(false);
    const [showMain,setMain] = useState("");
    const [activeCall, setActive] = useState(defaultCallStatus);
    let [timer, setTimer] = useState(0);
    const [timeState, setTime] = useState(0);
    const [saveSession, setSession] = useState(undefined);
    const [log, setLog] = useState("Idle");
    const [newOpt, setOptions] = useState(false);
    const [extList, getExtList] = useState(undefined);
    const [ringing, setRinging] = useState(false);
    const [audioOutputs, setOutputs] = useState(undefined);
    const [audioInputs, setInputs] = useState(undefined);
    const [videoDevices, setVideo] = useState([]);
    const [onCall, setCall] = useState(false);
    const [connected, setConnected] = useState(false);
    const [holdStatus, setHold] = useState(false);
    const [callerID, setCallerID] = useState("");
    const [extToTrans,setExt] = useState(undefined);
    const [txtButton, setTxt] = useState("Call");
    const [inco, setInco] = useState(false);
    const incomingCallAudio = new Audio("/incoming-call-ringtone.mp3");
    incomingCallAudio.loop = true;
    const dispatch = useDispatch();
    const [localAudio, setLocalAudio] = useState(new window.Audio());
    const audio = new window.Audio();
    audio.autoplay = true;
    const handleInputChange = (event) => {
        console.log(event);
        const {name, value} = event.target;
        setNumber(value);
    };
    const updateNumber = (event, savesession) => {
        const input = event.target.innerHTML;
        let dtmfPlayer = new DtmfPlayer();
        if("undefined" != number) {
            setNumber(number+input);
            dtmfPlayer.play(input);
            setTimeout(() => {
                dtmfPlayer.stop();
            },250);
            if(onCall){
                savesession.sendDTMF(input);
            }
        }
    }
    const eraseNumber = (event) => {
        if("undefined" != number) {
            setNumber(number.substring(0,number.length-1));
        }
    }
    const clean = (event) => {
        if("undefined" != number) {
            setNumber("");
        }
    }
    const eventHandlers = {
        'progress': function(e) {
            setLog('Ringing...');
        },
        'failed': function(e) {
            setLog(e.cause);
            setTimeout(() => {
                setLog('Idle');
            }, 1000);
            setNumber('');
            setSession(undefined);
            setCall(false);
            setConnected(false);
            incomingCallAudio.pause();
        },
        'ended': function(e) {
            setLog(e.cause);
            setTimeout(() => {
                setLog('Idle');
            }, 1000);
            setNumber('');
            setSession(undefined);
            setCall(false);
            setConnected(false);
            updateRefresh(false);
            incomingCallAudio.pause();
        },
        'confirmed': function(e) {
            setLog('Connected');
            setConnected(true);
            incomingCallAudio.pause();
        },
        'sending': function(e) {
            console.log('Sending INVITE');
        },
        'renegotiate': function(e) {
            setLog('Renegotiate Attempt');
        },
        'connecting': function(e) {
            setLog('Connecting...');
        },
        'sdp': function(e) {
            console.log('sdp exchange');
        }
    };
    const servers = {
        rtcpMuxPolicy: 'require',
        iceServers: [
            {urls:['stun:stun.l.google.com:19302','stun:stun1.l.google.com:19302','stun:stun2.l.google.com:19302','stun:stun3.l.google.com:19302','stun:stun4.l.google.com:19302']},
            {
                url: 'turn:numb.viagenie.ca',
                credential: 'muazkh',
                username: 'webrtc@live.com'
            }
        ]
    };
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const auth = getAuthClient();
    let { path, url } = useRouteMatch();
    const [showDevices, setShowDevices] = useState(false);
    const [selectedInput, setInput] = useState(false);
    const phoneListState                = useSelector(state => state.webphone.phoneNumbers);
    const [phoneList, setPhoneList] = useState(phoneListState);
    let [interval, setTimerRef]            = useState(0);
    const selectInput = (deviceId) => {
        let constraints, oldtrack;
        if ("Mute" == deviceId) {
            return false;
        } else {
            return {deviceId: {exact: deviceId}};
        }
    }
    const onChangeValue = (event) => {
        setInput(event.target.value);
    }
    const deviceType = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            var screen = document.getElementsByClassName("container-center-horizontal basic-screen")[0];
            screen.requestFullscreen().then(()=>{
                window.screen.orientation.lock("portrait");
                console.log("portrait");
            })
        }
        return "desktop";
    };
    const changeStatus = (unavailable) =>{
        setStatus(!unavailable)
        sessionStorage.setItem("status",!unavailable);
    }
    const clearState = () => {
        history.push("/");
    }
    const timeRefresh = 1;
    const [refresh, setRefresh]         = useState(false);
    useEffect(()=>{
        if(activeCall.timestamp !== undefined){
            updateRefresh(false);
        }
    },[activeCall])
    useEffect( async () => {
        if (undefined === extList) {
            if (process.env.REACT_APP_PBX_MAINREALM !== domain) {
                var domain_uuid = await auth.getData("domains")
                    .then((output) => {
                        console.log("Submitted");
                        console.log(output);
                        let uuid = Object.values(output.domains).filter(v => domain + ".cool-telecom.com" == v.domain_name)[0].domain_uuid
                        sessionStorage.setItem("domain_uuid", uuid);
                        return uuid;
                    })
                    .catch((error) => {
                        console.log('Login error', error);
                    });
                var exts = await auth.getData("extensions", "domain_uuid=" + domain_uuid)
                    .then((output) => {
                        let extList = [];
                        console.log("Submitted");
                        console.log(output);
                        Object.values(output.extensions).forEach(ext => extList.push(ext))
                        getExtList(extList.sort((a, b) => {
                            return a.extension - b.extension
                        }));
                        return output;
                    })
                    .catch((error) => {
                        console.log('Get ext error', error);
                    });
                if(phoneListState === []){
                    var dest = await auth.getData("destinations", "domain_uuid=" + domain_uuid)
                        .then((output) => {
                            let extList = [];
                            console.log("Submitted");
                            console.log(output);
                            sessionStorage.setItem("destination", Object.values(output.destinations).filter(x => x.destination_data.includes(sessionStorage.getItem('ext')))[0].destination_number)
                            dispatch(webphoneActions.updateExtensions(output))
                            return output;
                        })
                        .catch((error) => {
                            console.log('Get dest error', error);
                        });
                }
            } else {
                var exts = await auth.getData("extensions")
                    .then((output) => {
                        let extList = [];
                        console.log("Submitted");
                        console.log(output);
                        Object.values(output.extensions).forEach(ext => extList.push(ext))
                        getExtList(extList.sort((a, b) => {
                            return a.extension - b.extension
                        }));
                        dispatch(webphoneActions.updateExtensions(output))
                        return output;
                    })
                    .catch((error) => {
                        console.log('Get ext error', error);
                    });
                var dest = await auth.getData("destinations")
                    .then((output) => {
                        let extList = [];
                        console.log("Submitted");
                        console.log(output);
                        sessionStorage.setItem("destination", Object.values(output.destinations).filter(x => (x?.destination_data??"").includes(sessionStorage.getItem('ext')))[0].destination_number)
                        return output;
                    })
                    .catch((error) => {
                        console.log('Get dest error', error);
                    });
            }
        }
        var dest = await auth.getCRMData("/phoneNumbers/getPhonesByDomainName")
            .then((output) => {
                console.log("Submitted");
                dispatch(webphoneActions.updatePhones(output.filter(num => typeof output === "String")))
                setPhoneList(output)
                return output;
            })
            .catch((error) => {
                console.log('Login error', error);
            });
    }, []);
    useEffect( async () => {
        checkPermissions();
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioInputs = devices.filter(device => device.kind === 'audioinput');
        setInputs(audioInputs);
        console.log(audioInputs);
        const videos = devices.filter(device => device.kind === 'videoinput');
        console.log(videos);
        setVideo(videos);
    }, [showDevices]);
    const updateRefresh = (refresh) => {
        setRefresh(refresh);
        if(refresh) {
            let localInterval = setInterval(() => {
                    //loadLiveCalls();
                },
                timeRefresh * 1000)
            setTimerRef(localInterval);
        }
        else{
            clearInterval(interval);
        }
    }
    useEffect(() => {
        checkPermissions();
        var tempOpt = JSON.parse(JSON.stringify(options));
        tempOpt['eventHandlers'] = eventHandlers;
        setOptions(tempOpt);
        var myCandidateTimeout = null;

        try {
            var localUa = Webphone.newUa;
            if(!localUa){
                clearState()
            }
            localUa.on('newRTCSession', async (data) => {
                console.log('New RTC Session')
                updateRefresh(true);
                var session = data.session
                var completeSession = function () {
                    stopTimer();
                    setLog("Idle");
                    setTxt("Call");
                    setInco(false);
                    setCall(false);
                    setConnected(false);
                    setRinging(false);
                    setSession(undefined);
                    setShowKeys(false);
                    setTransMod("Transfer");
                    setShowTrans(false)
                    setMain("");
                    updateRefresh(false);
                    if("true" !== sessionStorage.getItem("status")){
                        history.push("/webphone/dialpad");
                    }
                    incomingCallAudio.pause();
                };
                session.on('getusermediafailed', function () {
                    alert('No device detected, please connect a headset.');
                });

                if (session.direction === "incoming" && "true" !== sessionStorage.getItem("status")) {
                    incomingCallAudio.play();
                    var caller_Id = data.request.headers.From[0].parsed.display_name;
                    setCallerID(caller_Id);
                    setLog('Incoming Call from ' + caller_Id);
                    setCall(false);
                    setConnected(false);
                    setInco(true);
                    setOpt("Dialpad");
                    setRinging(true);
                    setTxt("Answer");
                }
                if (session.direction === 'outgoing') {
                    console.log('stream outgoing  -------->');
                    setOpt("Dialpad");
                    var caller_Id = data.request.headers.To[0].split('@')[0].split(':')[1];
                    setCallerID(caller_Id);
                }
                ;
                session.on('connecting', function () {
                    console.log('CONNECTING');
                });
                session.on('ended', function () {
                    completeSession();
                    console.log('ended');
                });
                session.on('failed', function () {
                    completeSession();
                    console.log('failed');
                });
                session.on('accepted', function (e) {
                    console.log('accepted')
                });
                session.on('confirmed', function () {
                    var localStream = session.connection.getLocalStreams()[0];
                    var dtmfSender = session.connection.createDTMFSender(localStream.getAudioTracks()[0])
                    session.sendDTMF = function (tone) {
                        dtmfSender.insertDTMF(tone);
                    };
                    setConnected(true);
                    startTimer();
                    incomingCallAudio.pause();
                    setLog('Connected');
                });
                session.on('addstream', (event: any) => {
                    // set remote audio stream (to listen to remote audio)
                    // remoteAudio is <audio> element on page
                    console.log('adding remote stream  -------->');
                    //var remoteAudio = audio;
                    var remoteAudio = new window.Audio();
                    setLocalAudio( remoteAudio)
                    remoteAudio.srcObject = event.stream;
                    remoteAudio.play();
                    incomingCallAudio.pause();
                });
                session.on('icecandidate', function (candidate, ready) {
                    console.log('getting a candidate' + candidate.candidate.candidate);
                    if (myCandidateTimeout != null)
                        clearTimeout(myCandidateTimeout);

                    // 5 seconds timeout after the last icecandidate received!
                    myCandidateTimeout = setTimeout(candidate.ready, 400);
                });
                setSession(session);
            })
            setUa(localUa);

        } catch (e) {
            console.log(e);
        }

        deviceType();
    }, []);
    const selecProc = () => {
        if (!showDevices && audioInputs.length > 0 && false != selectedInput) {
            // eslint-disable-next-line no-unused-expressions
            if ("Mute" == selectedInput) {
                return false;
            } else {
                let input = audioInputs[selectedInput];
                let deviceId = input.deviceId;
                return {deviceId: {exact: deviceId}};
            }
        }
    }
    var options = {
        'mediaConstraints' : { 'audio': true, 'video': false },
        'pcConfig' : servers
    };
    String.prototype.toHMS = function () {
        var sec_num = parseInt(this, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return hours+':'+minutes+':'+seconds;
    }
    const checkPermissions = () => {
        var permissions = navigator.mediaDevices.getUserMedia({audio: true, video: false})
        permissions.then((stream) => {
            console.log('accepted the permissions');
            setMicPerm(true);
        })
            .catch((err) => {
                console.log(`${err.name} : ${err.message}`)
            });
    }
    const startTimer = () => {
        timer = setInterval(() => {
            setTime((timeState) => timeState + 1)
        }, 1000)
    }
    const stopTimer = () => {
        clearInterval(timer);
        setTime(0);
    }
    const showKeysFunc = () => {
        setShowKeys(!showKeys)
    }
    const showTransFunc = (type = "Transfer") => {
        setTransMod(type);
        setShowTrans(!showTrans)
        if("" == showMain){
            setMain(" none");
        }
        else{
            setMain("");
        }

    }
    const startCall = (phone="") => {
        if("" !== phone){
            setNumber(phone);
        }
        incomingCallAudio.pause();
        if(ringing){
            setRinging(false);
            saveSession.answer(newOpt);
            const myTimeout = setTimeout(() => {
                saveSession.connection.addEventListener('addstream', (event: any) => {
                    // set remote audio stream (to listen to remote audio)
                    // remoteAudio is <audio> element on page
                    console.log("add stream done.")
                    //var remoteAudio = audio;
                    var remoteAudio = new window.Audio();
                    setLocalAudio( remoteAudio)
                    remoteAudio.srcObject = event.stream;
                    remoteAudio.play();
                    console.log("add stream not done.")
                }, 10000);
            });
            setCall(true);
        }
        else if(""!==phone || ""!==number){
            const myTimeout = setTimeout(() => {
                if("" !== phone){
                    var localSession = Webphone.newUa.call(phone.replace(/[^0-9+*#]/g, ''), newOpt);
                }
                else {
                    var localSession = Webphone.newUa.call(number.replace(/[^0-9+*#]/g, ''), newOpt);
                }
                setSession(localSession);
                localSession.connection.addEventListener('addstream', (event: any) => {
                    // set remote audio stream (to listen to remote audio)
                    // remoteAudio is <audio> element on page
                    //var remoteAudio = audio;
                    var remoteAudio = new window.Audio();
                    setLocalAudio( remoteAudio)
                    remoteAudio.srcObject = event.stream;
                    remoteAudio.play();
                });
                localSession.on('ended', function () {
                    console.log('ended');
                });
                localSession.on('failed', function () {
                    console.log('failed');
                });
            }, 1000);
            setCall(true);
        }
    }
    const endCall = (session) => {
        stopTimer();
        updateRefresh(false);
        try {
            if (undefined !== saveSession) {
                setLog("Connected");
                setNumber("");
                setTxt("Call");
                setSession(undefined);
                setRinging(false);
                session.terminate();
                setCall(false);
                setConnected(false);
                incomingCallAudio.pause();
            }
        }
        catch (e) {
            console.log(e);
            setLog("Idle");
            setNumber("");
            setTxt("Call");
            setSession(undefined);
            setRinging(false);
            setCall(false);
            setConnected(false);
            incomingCallAudio.pause();
        }
    }
    function transCall(ext) {
        saveSession.hold();
        let eventHandlers = {
            'requestSucceeded': function (e) {
                console.log("Transfered Successfully");
            },
            'requestFailed': function (e) {
                console.log("Tranfer Failed");
            },
            'trying': function (e) {
                console.log("Trying", e);
            },
            'progress': function (e) {
                console.log("Progress", e);
            },
            'accepted': function (e) {
                console.log("Accepted", e);
            },
            'failed': function (e) {
                console.log("Failed", e);
            },
        };
        newOpt['eventHandlers'] = eventHandlers;
        newOpt['inviteWithoutSdp'] = true;
        newOpt['activeAfterTransfer'] = false;
        try {
            var myCandidateTimeout = null;
            saveSession.on('icecandidate', function(candidate, ready) {
                console.log('getting a candidate' + candidate.candidate.candidate);
                if (myCandidateTimeout!=null)
                    clearTimeout(myCandidateTimeout);

                // 5 seconds timeout after the last icecandidate received!
                myCandidateTimeout = setTimeout(candidate.ready, 1000);
            })
            saveSession.refer("sip:"+ext+"@"+domain+".cool-telecom.com", newOpt);
            showTransFunc();
        } catch (err) {
            console.log("We can't execut that commande: "+err);
        }
    }
    const mute = () =>{
        if(muted){
            saveSession.unmute();
            setMuted(false);
            setLog("Connected");
        }
        else{
            saveSession.mute();
            setMuted(true);
            setLog("Muted");
        }
    }
    const hold = () =>{
        if(holdStatus){
            saveSession.unhold();
            setHold(false);
            setLog("Connected");
        }
        else{
            saveSession.hold();
            setHold(true);
            setLog("On Hold");
        }
    }
    const loadLiveCalls = async () => {
        let ext_id = JSON.parse(sessionStorage.getItem("extension_full")).extension;
        var live = await auth.getData('show_calls')
            .then((output) => {
                console.log("Submitted");
                console.log(output);
                return output;
            })
            .catch((error) => {
                console.log('Login error', error);
            });
        try {
            console.log("Try");
            delete(live.remoute_url)
            let local_active = Object.values(live);
            if(local_active.length > 0) {
                let active = local_active[0]
                setActive({
                    'extension': active.presence_id.split("@")[0],
                    'uuid':active.uuid,
                    'timestamp': active['created'],
                    'dest': active['dest'],
                    'direction': active['b_direction'],
                    'state': active['callstate'],
                })
            }
        } catch (error) {
            console.log("No data");
        }
    }
    const [menuOpt,setOpt] = useState("Dialpad");
    const subRouteList = [{comp: History, name:'History', img: HistoryIcon},
        {comp: Dialpad, name:'Dialpad', img: DialpadIcon},
        /*{comp: Contacts, name:'Contacts', img:ContactsIcon},*/
        {comp: Settings, name:'Settings', img: SettingsIcon},
    ]
    const BottomMenu = () => {
        let history = useHistory();
        const nameToLowerCase = (name) => {
            return name.toLowerCase().replace(" ","-")
        }
        return(<div className="bottom_menu">
            {Object.values(subRouteList).map((key, j) => {
                let selected = "filter-gray";
                if(menuOpt == key.name){
                    selected = "filter-green";
                }
                let tempName = nameToLowerCase(key.name);
                let CustomIcon = key.img;
                return (
                    <div className="overlap-group clickable bottom-icon-wrapper" onClick={() => {
                        setOpt(key.name);
                        // eslint-disable-next-line no-restricted-globals
                        console.log({tempName});
                        // eslint-disable-next-line no-restricted-globals
                        history.push(`${url}/${tempName}`);
                    }}>
                        <CustomIcon className={selected}/>
                        <div className={"dbody3" + " " + selected}>{key.name}</div>
                    </div>
                )})}
        </div>);
    }
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                startCall(number);
            }
        };
        window.addEventListener("keydown", listener);
        return () => {
            window.removeEventListener("keydown", listener);
        };
    }, [number]);
    useEffect(()=>{
        if(unavailable){
            Webphone.newUa.stop()
        }
        else{
            Webphone.newUa.start()
            setOpt("Dialpad")
        }
    },[unavailable])
    return(
        <div className={"container-center-horizontal basic-screen"+(onCall? " on-call":"")+(showKeys?" keyboard-open":"")+(inco?" incoming-call":"")}>
            <TopBarStatus audio={localAudio}/>
            {"Dialpad" === menuOpt?
        <>
            <>{showTrans? <TransferCall moveOut={showTransFunc} transfer={transCall} extList={extList} mode={transMod} call={activeCall}/>: <></>}</>
            <div className={"webphone-dial screen"+showMain}>
            <>{false == onCall && false == inco?
                    <IdleScreen
                                updateNumber={updateNumber}
                                number={number} clean={clean}
                                eraseNumber={eraseNumber}
                                phoneList={phoneList}
                                handleInputChange={handleInputChange}
                                saveSession={saveSession}
                                startCall={startCall}/>:
                <>
                    {showKeys?
                        <OnCallScreenDial updateNumber={updateNumber}
                                          session={saveSession}
                                          startCall={startCall}
                                          number={number}
                                          handleInputChange={handleInputChange}
                                          eraseNumber={eraseNumber}
                                          clean={clean}
                                          endCall={endCall}
                                          onCall={onCall}
                                          setShowKeys={setShowKeys}/>:
                    <OnCallScreen onCall={onCall}
                                  endCall={endCall}
                                  number={number}
                                  startCall={startCall}
                                  hold={hold}
                                  mute={mute}
                                  session={saveSession}
                                  showKeysFunc={showKeysFunc}
                                  connected={connected}
                                  inco={inco}
                                  log={log}
                                  showTransFunc={showTransFunc}
                                  timeState={timeState}
                    />}
                </>}
            </>
        </div></>:
            <Switch>
                <Route path={`${url}/dialpad`}>
                    <></>
                </Route>
                <Route path={`${url}/settings`}>
                    <Settings unavailable={unavailable} setStatus={changeStatus}/>
                </Route>
                <Route path={`${url}/history`}>
                    <History startCall={startCall}/>
                </Route>
                <Route path={`${url}/contacts`}>
                    <Contacts/>
                </Route>
            </Switch>}
            {onCall || inco?<></>:<BottomMenu/>}
        </div>
    )
}

export default Dialpad;