import React, {useState,useEffect} from 'react';
import coolLogo from "../../assets/cool-telecom-logo.svg";
import girl from "../../assets/phonegirl.png";
import Input from "../ui/Input";
import TwinSpin from "react-cssfx-loading/lib/TwinSpin";
import "./LoginForm.css";
import Button from "../ui/Button";
import {useHistory, useParams} from "react-router-dom";
import Webphone from "../../Webphone";
import {useSelector, useDispatch} from "react-redux";
import {getAuthClient} from "../../utils/auth";
import {authActions} from "../../store/auth-slice";
import {userActions} from "../../store/user-info-slice";

const LoginForm = (props) => {
    const webphoneState = useSelector(state => state.webphone);
    const [newUa, setNewUa] = useState(false);
    const [values, setValues] = useState({name:'', pass:''});
    const [isLoading, setLoading] = useState(false);
    const [registered, setRegistered] = useState(false);
    const history = useHistory();
    const { token = undefined, show_alert = true } = useParams();
    const auth = getAuthClient();
    const dispatch = useDispatch();
    const handleInputChange = (event) => {
        console.log(event);
        const {name, value} = event.target;
        setValues({...values, [name]: value});
    };
    useEffect(async () => {
        console.log("RESIZING");
        let ext = sessionStorage.getItem('ext');
        let pass = sessionStorage.getItem('pass');
        let domain = sessionStorage.getItem('domain');
        if (ext !== null && domain !== null && pass !== null && token === undefined) {
            Webphone.init({domain: domain, ext: ext, pass: pass});
            setLoading(true);
            Webphone.newUa.on('registered', function (e) {
                history.push("/webphone");
                setLoading(false);
            });
            Webphone.newUa.on('registrationFailed', function (e) {
                history.push("/");
                setLoading(false);
                alert("Registration Failed.")
            });
        }
        else if (token !== undefined) {
            setLoading(true);
            sessionStorage.setItem("access_token", token);
            await getExtensionDetails()
            await auth.getCRMData(`/users/getOneUser`).then(userDatas =>{
                dispatch(userActions.update({   first_name: userDatas?.field_first_name,
                    last_name: userDatas?.field_last_name,
                    user_email: userDatas?.email,
                    uuid: userDatas?.uuid,
                    domain: userDatas?.domain_name,
                    domain_uuid: userDatas?.domainUuid,
                    fusion_uuid: userDatas?.domain?.fusionUuid,
                    domain_data: userDatas?.domain}))
            }).catch(e => {
                console.error(e)
            })
        }
        deviceType();

    }, []);

    useEffect(() => {
        if(newUa){
            history.push("/dialpad");
        }
    }, [newUa]);

    const deviceType = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            var screen = document.getElementsByClassName("container-center-horizontal basic-screen")[0];
            screen.requestFullscreen().then(()=>{
                window.screen.orientation.lock("portrait");
                console.log("portrait");
            })
        }
        return "desktop";
    };

    const getExtensionDetails = async () => {
        await auth.getUserExtension()
            .then((output) => {
                let extList = [];
                console.log("Submitted");
                if (output?.data?.rows.length > 0) {
                    let extension = (output?.data?.rows??[])[0];
                    let ext = extension.extension;
                    let domain = extension.accountcode.split(".cool-telecom.com")[0];
                    let pass = extension.password
                    sessionStorage.setItem('ext', ext);
                    sessionStorage.setItem('domain', domain);
                    sessionStorage.setItem('pass', pass);
                    sessionStorage.setItem('destination', extension.outbound_caller_id_name);
                    sessionStorage.setItem('extension_full', JSON.stringify(extension));
                    Webphone.init({ext: ext, domain: domain, pass: pass});
                    Webphone.newUa.on('registered', function (e) {
                        setLoading(false);
                        if(show_alert !== "false"){
                            alert("Registered.");
                        }
                        history.push("/webphone");
                    });
                    Webphone.newUa.on('registrationFailed', function (e) {
                        history.push("/");
                        setLoading(false);
                        alert("Registration Failed.")
                    });
                }
                return output;
            })
            .catch((error) => {
                console.log('Get ext error', error);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        sessionStorage.setItem('name', values.name);
        sessionStorage.setItem('userpass', values.pass);
        setLoading(true);
        auth.login(values.name, values.pass)
            .then(async () => {
                dispatch(authActions.login());
                await getExtensionDetails()

            })
            .catch((error) => {
                dispatch(authActions.logout())
                history.push("/");
                setLoading(false);
                alert("Login Failed.")
            });

    }
    return (
        <div className={"container-center-horizontal basic-screen login"}>
            {(isLoading) ?
                <div className="flex-row-2">
                    <TwinSpin color="var(--caribbean-green)"/>
                </div>
                :
                <div className={"login-form row-mid-flex"}>
                    <img className={"logos"} id="cool-logo" src={coolLogo}/>
                    <img className={"logos"} id="girl_illustration" src={girl}/>
                    <div style={{width: "95%"}}>
                        <form className={"row-mid-flex"} onSubmit={handleSubmit}>
                            <Input label={"Username"} name={"name"} defaultValue={values.name}
                                   onChange={handleInputChange} classInput={"border-1px-black90 dbody3"}
                                   classLabel={"col-start-flex dbody3"}/>
                            <Input label={"Password"} name={"pass"} defaultValue={values.pass}
                                   onChange={handleInputChange} classInput={"border-1px-black90 dbody3"}
                                   classLabel={"col-start-flex dbody3"}/>
                            <Button name={"Sign In"} classButton={"button-typo"}/>
                        </form>
                    </div>
                </div>
            } </div>
    );
};


export default LoginForm;