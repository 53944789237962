import JsSIP from "jssip";
import {getAuthClient} from "./utils/auth";

class Webphone {
    constructor() {
        this.number = "";
        this.saveSession = undefined;
        this.log = undefined;
        this.newOpt = false;
        this.extList = undefined;
        this.newUa = false;
        this.audio = undefined;
        this.ringing = false;
        this.onCall = false;
        this.holdStatus = false;
        this.callerID = "";
        this.extToTrans = undefined;
        this.txtButton = "Call";
        this.domain = `@${process.env.REACT_APP_PBX_REALM}`;

        this.callOptions = {
            mediaConstraints: {audio: true, video: false}
        };
        this.servers = {
            rtcpMuxPolicy: 'require',
            iceServers: [
                {urls:['stun:stun.l.google.com:19302','stun:stun1.l.google.com:19302','stun:stun2.l.google.com:19302','stun:stun3.l.google.com:19302','stun:stun4.l.google.com:19302']},
                {
                    url: 'turn:numb.viagenie.ca',
                    credential: 'muazkh',
                    username: 'webrtc@live.com'
                }
            ]
        };
        JsSIP.debug.enable('JsSIP:*');
    }
    async init(data) {
        try {
            // eslint-disable-next-line no-sequences
            if ('domain', 'ext', 'pass' in data && data.pass != null) {
                var conf = this.configuration(data)
                var options = conf.options;
                var ua = conf.ua;
                var tempOpt = JSON.parse(JSON.stringify(options));
                tempOpt['eventHandlers'] = this.eventHandlers;
                this.newOpt = tempOpt;
                var myCandidateTimeout = null;
                /*ua.on('newRTCSession', (data) => {
                    console.log('New RTC Session')
                    var session = data.session
                    var completeSession = function(){
                        this.log = "";
                        this.txt = "Call";
                        this.onCall = false;
                        this.ringing = false;
                        this.saveSession = undefined;
                        //this.incomingCallAudio.pause();
                    };
                    session.on('getusermediafailed', function() {
                        console.log('No device detected, please connect a headset.');
                    });

                    if (session.direction === "incoming") {
                        //this.incomingCallAudio.play();
                        var caller_Id = data.request.headers.From[0].parsed.display_name;
                        this.callerID = caller_Id;
                        this.log = 'Incoming Call from '+caller_Id;
                        this.ringing = true;
                        this.txtButton = "Answer"
                    }
                    if(session.direction === 'outgoing'){
                        console.log('stream outgoing  -------->');
                        var caller_Id = data.request.headers.To[0].split('@')[0].split(':')[1];
                        this.callerID = caller_Id;
                    };
                    session.on('connecting', function() {
                        console.log('CONNECTING');
                    });
                    session.on('ended', completeSession);
                    session.on('failed', completeSession);
                    session.on('accepted',function(e) {
                        console.log('accepted')
                    });
                    session.on('confirmed',function(){
                        var localStream = session.connection.getLocalStreams()[0];
                        var dtmfSender = session.connection.createDTMFSender(localStream.getAudioTracks()[0])
                        session.sendDTMF = function(tone){
                            dtmfSender.insertDTMF(tone);
                        };
                        this.log = 'Connected to '+ this.callerId;
                    });
                    session.on('addstream', (event) => {
                        // set remote audio stream (to listen to remote audio)
                        // remoteAudio is <audio> element on page
                        var remoteAudio = audio;
                        remoteAudio.srcObject = event.stream;
                        remoteAudio.play();
                    });
                    session.on('icecandidate', function(candidate, ready) {
                        console.log('getting a candidate' + candidate.candidate.candidate);
                        if (myCandidateTimeout!=null)
                            clearTimeout(myCandidateTimeout);

                        // 5 seconds timeout after the last icecandidate received!
                        myCandidateTimeout = setTimeout(candidate.ready, 400);
                    });
                    this.saveSession = session;
                })*/
                this.newUa = ua;
                return true;
            }
        } catch (e) {
            console.log(e);
        }
    }

    configuration (values) {
        const auth = getAuthClient();
        const socket = new JsSIP.WebSocketInterface(`wss://${values?.domain}${process.env.REACT_APP_PBX_BASEREALM}:7443/ws`);
        const configuration = {
            sockets  : [ socket ],
            uri      : `sip:${values?.ext}@${values?.domain}${process.env.REACT_APP_PBX_BASEREALM}`,
            password : values.pass,
            session_timers: false,
            stun_servers : ['stun:stun.l.google.com:19302','stun:stun1.l.google.com:19302','stun:stun2.l.google.com:19302','stun:stun3.l.google.com:19302','stun:stun4.l.google.com:19302'],
            turn_servers : ['turn:turn01.hubl.in?transport=udp','turn:turn02.hubl.in?transport=tcp']
        };

        console.log(values);
        var ua = new JsSIP.UA(configuration);
        ua.start();

        ua.on("registered", () =>{
            sessionStorage.setItem('ext', values.ext);
            sessionStorage.setItem('domain', values.domain);
            return ua;
        });
        ua.on("connected", () =>{
            sessionStorage.setItem('ext', values.ext);
            sessionStorage.setItem('domain', values.domain);
            sessionStorage.setItem('registered', true);
            return ua;
        });
        console.log(configuration);

        const servers = {
            rtcpMuxPolicy: 'require',
            iceServers: [
                {urls:['stun:stun.l.google.com:19302','stun:stun1.l.google.com:19302','stun:stun2.l.google.com:19302','stun:stun3.l.google.com:19302','stun:stun4.l.google.com:19302']},
                {
                    url: 'turn:numb.viagenie.ca',
                    credential: 'muazkh',
                    username: 'webrtc@live.com'
                }
            ]
        };

        var options = {
            'mediaConstraints' : { 'audio': true, 'video': false },
            'pcConfig' : servers
        };
        return {ua:ua, options:options};
    }

    startCall = (number,audio, newOpt, incomingCallAudio) => {
        incomingCallAudio.pause();
        if(this.ringing){
            this.ringing = false;
            this.saveSession.answer(newOpt);
            const myTimeout = setTimeout(() => {
                this.saveSession.connection.addEventListener('addstream', (event) => {
                    // set remote audio stream (to listen to remote audio)
                    // remoteAudio is <audio> element on page
                    var remoteAudio = audio;
                    remoteAudio.srcObject = event.stream;
                    remoteAudio.play();
                }, 10000);
            });
            this.onCall = true;
        }
        else {
            const myTimeout = setTimeout(() => {
                var localSession = this.newUa.call(number, newOpt);
                this.saveSession = localSession;
                localSession.connection.addEventListener('addstream', (event) => {
                    // set remote audio stream (to listen to remote audio)
                    // remoteAudio is <audio> element on page
                    var remoteAudio = audio;
                    console.log(event);
                    remoteAudio.srcObject = event.stream;
                    remoteAudio.play();
                });
            }, 1000);
            this.onCall = true ;
        }
    }
    endCall = (session, incomingCallAudio) => {
        try {
            if (undefined != this.saveSession) {
                this.log = "";
                this.number = "";
                this.call = "Call";
                this.saveSession = undefined;
                this.ringing = false;
                session.terminate();
                this.onCall = false;
                incomingCallAudio.pause();
            }
        }
        catch (e) {
            console.log(e);
            this.log = "";
            this.number = "";
            this.call = "Call";
            this.saveSession = undefined;
            this.ringing = false;
            this.onCall = false;
            incomingCallAudio.pause();
        }
    }

    transCall(extToTrans, newOpt) {
        this.saveSession.hold();
        let eventHandlers = {
            'requestSucceeded': function (e) {
                console.log("Transfered Successfully");
            },
            'requestFailed': function (e) {
                console.log("Tranfer Failed");
            },
            'trying': function (e) {
                console.log("Trying", e);
            },
            'progress': function (e) {
                console.log("Progress", e);
            },
            'accepted': function (e) {
                console.log("Accepted", e);
            },
            'failed': function (e) {
                console.log("Failed", e);
            },
        };
        newOpt['eventHandlers'] = eventHandlers;
        newOpt['inviteWithoutSdp'] = true;
        newOpt['activeAfterTransfer'] = false;
        try {
            var myCandidateTimeout = null;
            this.saveSession.on('icecandidate', function(candidate, ready) {
                console.log('getting a candidate' + candidate.candidate.candidate);
                if (myCandidateTimeout!=null)
                    clearTimeout(myCandidateTimeout);

                // 5 seconds timeout after the last icecandidate received!
                myCandidateTimeout = setTimeout(candidate.ready, 1000);
            })
            this.saveSession.refer("sip:"+extToTrans+sessionStorage.getItem('domain'), newOpt);
        } catch (err) {
            console.log("We can't execut that commande: "+err);
        }
    }

    reconnect(){
        let ext = sessionStorage.getItem('ext');
        let domain = sessionStorage.getItem('domain');
        let pass = sessionStorage.getItem('pass');
        if(ext !== null && domain !== null && pass !== null){
            let data = {domain:domain, ext:ext, pass:pass}
            var conf = this.configuration(data)
            var options = conf.options;
            var ua = conf.ua;
            var tempOpt = JSON.parse(JSON.stringify(options));
            tempOpt['eventHandlers'] = this.eventHandlers;
            this.newOpt = tempOpt;
            this.newUa = ua;
            return ua
        }
        else{
            return false
        }
    }

}
export default new Webphone()