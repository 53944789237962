import "./TransferCall.css";
import PhoneInput from "../../ui/PhoneInput";
import React, {useEffect, useState} from "react";
import {getAuthClient} from "../../../utils/auth";
import {useDispatch, useSelector} from "react-redux";
import {webphoneActions} from "../../../store/webphone-state-slice";

const TransferCall = (props) => {
    const extensionList = useSelector(state => state.webphone.extensions);
    const users         = useSelector(state => state.webphone.manage_user);
    const userData      = useSelector(state => state.user.userInfos);
    const {moveOut, transfer, extList, mode, call, uuid=undefined} = props;
    const [name, setName] = useState("");
    const [extSelect, setExt] = useState("");
    const dispatch = useDispatch();
    const auth = getAuthClient();
    const clean = (event) => {
        if("undefined" != name) {
            setName("");
        }
    }
    const handleInputChange = (event) => {
        console.log(event);
        const {name, value} = event.target;
        actionCall(call, mode);
        setName(value);
    };

    const checkMatch = (str1,str2) =>{
        let len = str2.length;
        if(str1.substring(0,len) == str2){
            return true;
        }
        else{
            return false;
        }
    }

    useEffect(()=>{
        auth.getCRMData("/users/getAllUsersByDomain")
            .then((result) => {
                if (result !== undefined) {
                    dispatch(webphoneActions.updateUsers(result));
                }
            })
            .catch((e) => {
                console.log(e);
            });
    },[])

     const actionCall = async (call, action="", extSelect = undefined,  param=undefined) => {
        let local_uuid = call.uuid;
        const orig_uuid = call.uuid;
        let selectedExt = extSelect;
        if(action !== "uuid_hold"){
            if(mode === "ADD"){
                action = "uuid_conference"
            }
            else{
                action = "uuid_transfer"
            }
            let localDestExt = Object.values(extList).filter(ext => ext.extension === selectedExt.extension)
            let destMod = "mod_dest=" + (localDestExt.length > 0 ? "internal" : "external");
            let destId = localDestExt.length > 0 ? "dest_id="+localDestExt[0].outbound_caller_id_number : "";
            let localSrcExt = JSON.parse(sessionStorage.getItem("extension_full"))
            let src_cid_name = localSrcExt.outbound_caller_id_name;
            let src_cid_number = localSrcExt.outbound_caller_id_number;
            let extension = localSrcExt.extension;
            let curr_dest = localDestExt[0].outbound_caller_id_number;
            param = "destination=" + selectedExt.extension +"&src_cid_name=" + (src_cid_name??extension) +"&src_cid_number="+src_cid_number+"&extension="+extension+"&auto_answer=true"+"&curr_dest="+curr_dest;
            if(action === "uuid_conference"){
                let ext = param.split("&").filter((line) => line.includes("extension"))[0].split("=")[1];
                auth.getData("uuid_transfer", { 'param': "uuid="+orig_uuid+"&direction=both&destination=3000"+ext}).then(async (output) => {
                        console.log(output);
                        if(param!== undefined){
                            local_uuid += "&"+param;
                        }
                        var response = await auth.getData(action, {'param': "uuid=" + local_uuid})
                            .then((output) => {
                                console.log("Submitted");
                                console.log(output);
                                moveOut();
                                return output;
                            })
                            .catch((error) => {
                                console.log('Login error', error);
                            });
                    }
                ).catch(e => {
                    console.log(e)
                })
            }
            else{
                var response = await auth.getData(action, {'param': "uuid=" + local_uuid})
                    .then((output) => {
                        console.log("Submitted");
                        console.log(output);
                        moveOut();
                        return output;
                    })
                    .catch((error) => {
                        console.log('Login error', error);
                    });
            }
        }
        else{
            var response = await auth.getData(action, { 'param': "uuid="+local_uuid})
                .then((output) => {
                    console.log("Submitted");
                    console.log(output);
                    return output;
                })
                .catch((error) => {
                    console.log('Login error', error);
                });
        }

    }

    useEffect(()=>{
        actionCall(call, "uuid_hold");
    },[])

    return (
        <div className="webphone-dial webphone-calling-add screen">
            <div className="flex-row">
                <div className="back" onClick={()=>{
                    {
                        setName("");
                        moveOut();
                    }}}>
                    <img
                        src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61dc5ce973169b381a31955c/img/24-7@2x.svg"
                    />
                </div>
                <div className="transfer-call-to dh2">{mode === "Transfer"?"Transfer call to":"Add to call"}</div>
            </div>
            <div className={"top"}>
            <PhoneInput label={""} name={"number"} value={name} defaultValue={name} onChange={handleInputChange} clean={clean} classInput={"borderless-input dbody3 number"} classLabel={"col-start-flex dbody3 phone-input"}/>
            <div className="divider"></div>
            <div className={"extTabs"}>
            {extList !== undefined? extList.map((r) => {
                let selected = "";
                if(checkMatch(r.extension,name)){
                    if(extSelect.extension == r.extension){
                        selected = " selected";
                    }
                    let filteredUsers = users.filter(user => user?.extension?.name === r.extension)[0]??false
                    return (
                        <div className={"string-1 dbody-1"+selected} onClick={()=> {
                            setExt(r)
                        }}>
                            <div className="ext-tab">
                                <div className="dh4">{r.extension}</div>
                            </div>
                            <div className="dbody3">{filteredUsers?`${filteredUsers?.field_first_name} ${filteredUsers?.field_last_name}`:"(Unassigned)"}</div>
                        </div>
                    )
                }
                }
            ):<span>No Extension</span>}
            </div>
            </div>
            <>{extSelect == ""?
                <div className="buttons-container">
                    {mode === "ADD"?
                        <div className="buttons buttons-1 zoomEffect">
                            <div className="label label-1 valign-text-middle dh4">Add now</div>
                        </div>
                        :
                        <div className="buttons buttons-1 zoomEffect">
                        <div className="label label-1 valign-text-middle dh4">Transfer now</div>
                    </div>}

                </div>:
                <div className="buttons-container">
                    {mode === "ADD"?
                        <div className="buttons zoomEffect buttons-1 buttons-1-select">
                            <div className="label label-1 label-select-1 valign-text-middle dh4" onClick={()=>actionCall(call, mode, extSelect)}>Add now</div>
                        </div>:<div className="buttons zoomEffect buttons-1 buttons-1-select">
                            <div className="label label-1 label-select-1 valign-text-middle dh4" onClick={()=> {
                                transfer(extSelect.extension)
                            }}>Transfer now</div>
                        </div>
                    }

                </div>}
            </>
        </div>
    )
}

export default TransferCall;