import nohist from "./no-history.png";
import "./History.css"
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import Webphone from "../../../Webphone";
import {format} from "date-fns";
import {getAuthClient} from "../../../utils/auth";
import HistElement from "./HistElement";
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import {TwinSpin} from "react-cssfx-loading";

const History = (props) => {
    const {startCall} = props;
    const auth                          = getAuthClient();
    const domain                        = sessionStorage.getItem("domain");
    const domain_uuid                   = sessionStorage.getItem("domain_uuid");
    const [startDate, setStartDate]     = useState(new Date().setHours(0,0,0,0));
    const [endDate, setEndDate]         = useState(new Date());
    const [hist,setHistory]             = useState(undefined);
    const [isLoading, setLoading]       = useState(true);

    useEffect( async () => {
        let extension = sessionStorage.getItem("ext");

        var cdr = await auth.getData("xml_cdr",{'date': `start_stamp[]= ${format(startDate, "yyyy-MM-dd")}+00:00:00&start_stamp[]=${format(endDate, "yyyy-MM-dd")}+23:59:59`}, {"extension": extension})
            .then((output) => {
                let list = [];
                console.log("Submitted");
                console.log(output);
                let xml = output.xml_cdr;
                xml.sort((a, b) => {  return new Date(b.start_stamp) - new Date(a.start_stamp);})
                Object.values(xml).forEach(record => {
                    let phone = "";
                    if("outbound" === record.direction){
                        phone = record.destination_number.replace("+","")
                        list.push({'type':'outbound', 'phone':phone, 'time':record.start_stamp.split("+")[0]})
                    }
                    else if("inbound" === record.direction && "" !== record.source_number){
                        phone = record.caller_id_number.replace("+","")
                        let type = "inbound";
                        if("NORMAL_CLEARING"!==record.hangup_cause){
                            type = "missed";
                        }
                        list.push({'type':type, 'phone':phone, 'time':record.start_stamp.split("+")[0]})
                    }
                })
                setHistory(list);
                setLoading(false)
                return output;
            })
            .catch((error) => {
                console.log('Get ext error', error);
                setLoading(false)
            });
    }, []);

    const copyToClipboard = str => {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText)
            return navigator.clipboard.writeText(str);
        return Promise.reject('The Clipboard API is not available.');
    };

    const Popup = (number) => {
        return(
            <div className="more_popup_menu">
                <div className="frame-1456">
                    <img className="x24-10"
                         src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61fbb56136ac1d7db4d9d2de/img/24-12@2x.svg"/>
                        <div className="value dbody-2">Call Back</div>
                </div>
                <div className="frame-145">
                    <img className="x24-13"
                         src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61fbb56136ac1d7db4d9d2de/img/24-15@2x.svg"/>
                        <div className="value-3 dbody-2">Copy phone number</div>
                </div>
            </div>
        )
    }
    return(
        <div className="webphone-history screen">
            <h1 className="title dh2">History</h1>
            <>{isLoading?<div className={"loading-spin-wrapper"}><TwinSpin/></div>:(undefined === hist || hist.length === 0)?
                <div className="icon">
                    <img
                        className="group-1973"
                        src={nohist}
                    />
                    <p className="you-didnt-have-any-calls-yet dbody2">You didn’t have any calls yet</p>
                </div>:
                <div className={'history-tab'} id={'custom-scroll'}>
                    {Object.values(hist).map(record => {
                        return(
                            <HistElement type={record.type} phone={record.phone} time={record.time} copy={copyToClipboard} call={startCall}/>
                        )
                    })}
                </div>}
            </>
        </div>)
}

export default History;