/**
 * @file
 *
 * Wrapper around fetch(), and OAuth access token handling operations.
 *
 * To use import getAuthClient, and initialize a client:
 * const auth = getAuthClient(optionalConfig);
 */
import axios from "axios";
import {userActions} from "../store/user-info-slice";
import store from "../store";

const refreshPromises = [];

/**
 * OAuth client factory.
 *
 * @param {object} config
 *
 * @returns {object}
 *   Returns an object of functions with $config injected.
 */
export function getAuthClient(config = {}) {
    const defaultConfig = {
        // Base URL of your Drupal site.
        base: process.env.REACT_APP_SERVER_URL,
        base_crm: process.env.REACT_APP_BASE_CRM,
        // Name to use when storing the token in sessionStorage.
        token_name: process.env.REACT_APP_TOKEN_NAME,
        // OAuth client ID - get from Drupal.
        client_id: process.env.REACT_APP_CLIENT_ID, // @see default cosnumer into drupal
        // OAuth client secret - set in Drupal.
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        // Drupal user role related to this OAuth client.
        scope: process.env.REACT_APP_SKOPE,
        // Margin of time before the current token expires that we should force a
        // token refresh.
        expire_margin: process.env.REACT_APP_EXPIRE_REFRESH,

        //settings for create account
        create_account: process.env.REACT_APP_CREATE_ACCOUNT,
        verify_account: process.env.REACT_APP_VERIFY_ACCOUNT,
    };


    config = {...defaultConfig, ...config}

    /**
     * Exchange a username & password for an OAuth token.
     *
     * @param {string} username
     * @param {string} password
     */
    async function login(username, password, domain="") {
        try {
            const response = await axios({
                method: "post",
                url: `${config.base_crm}/users/login`,
                data: {'email': username,'password': password, domain},
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
            })
            const data = await response?.data;
            if (data.error) {
                console.log('Error retrieving token', data);
                //errorHandler(data.error,{message:data.error})
                return Promise.reject(new Error(`Error retrieving OAuth token: ${data.error}`));
            }
            let userDatas = response?.data?.userData
            saveToken(response?.data?.token, userDatas?.uuid)
            store.dispatch(userActions.update({   first_name: userDatas?.field_first_name,
                last_name: userDatas?.field_last_name,
                user_email: userDatas?.email,
                uuid: userDatas?.uuid,
                domain: userDatas?.domain_name,
                domain_uuid: userDatas?.domainUuid,
                fusion_uuid: userDatas?.domain?.fusionUuid,
                domain_data: userDatas?.domain}))
        } catch (err) {
            console.log('API got an error', err);
            //errorHandler(err,{message:err?.response?.data?.message})
            return Promise.reject(err);
        }
    };

    /**
     * Exchange a username & password for an OAuth token.
     *
     * @param {string} username
     * @param {string} password
     */

    /**
     * Delete the stored OAuth token, effectively ending the user's session.
     */
    function logout() {
        sessionStorage.removeItem(config.token_name);
        return Promise.resolve(true);
    };

    /**
     * Get extension list from fusion's server.
     */
    async function getData(type, param = null) {
        console.log(param);
        let formData = new FormData();
        if (param) {
            Object.keys(param).map((key, index) => (formData.append(key, param[key])));
        }
        try {
            const response = await fetchWithAuthentication(`/ct_base/get_data/` + type, {
                method: 'post',
                headers: new Headers({
                    'Accept': 'application/json'}),
                body: formData,
            });
            const data = await response.json();
            console.log(data);
            return data;
            /*if (data.error) {
                console.log('Error getting data', data);
            }*/
        } catch (err) {
            console.log('API got an error', err);
            return Promise.reject(new Error(`API error: ${err}`));
        }
    }

    /**
     * Get CRM Data
     */
    async function getCRMData(url, type = undefined, extraOptions = {}){
        const   oauth_token = await token();
        var     bearer = '';
        // forms post_data or just json
        if (oauth_token) {
            bearer =  `Bearer ${oauth_token.access_token}`;
        }
        let configAxios = {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': bearer
            }
        };

        try {
            if(type !== undefined){
                configAxios.headers["Content-Type"] = type
            }
            configAxios = {...configAxios, ...extraOptions}
            const response = await axios.get(`${config.base_crm}${url}`, configAxios);
            const response_data = await response.data;
            return response_data??null;
        } catch (err) {
            console.log(err)
        }
    }

    /**
     * Get extension list from fusion's server.
     */
    async function getUserExtension() {
        try {
            const response = await fetchWithAuthentication(`/fpbx/extensions?uuid=self`, {
                method: 'get',
                headers: new Headers({
                    'Accept': 'application/json'})
            });
            const data = await response.json();
            console.log(data);
            return data;
        } catch (err) {
            console.log('API got an error', err);
            return Promise.reject(new Error(`API error: ${err}`));
        }
    }

    /**
     * Get extension list from fusion's server.
     */
    async function getPhones() {
        try {
            let domain = sessionStorage.getItem("domain")
            const response = await fetchWithAuthentication(`/ct_base/get_phones/${domain}`, {
                method: 'post',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'})
            });
            const data = await response.json();
            console.log(data);
            return data;
        } catch (err) {
            console.log('API got an error', err);
            return Promise.reject(new Error(`API error: ${err}`));
        }
    }

    /**
     * Get extension list from fusion's server.
     */
    async function changePhone(param = null) {
        let formData = new FormData();
        if (param) {
            Object.keys(param).map((key, index) => (formData.append(key, param[key])));
        }
        try {
            param = {...param, uuid:param.extension_uuid}
            let domain = sessionStorage.getItem("domain")
            const response = await fetchWithAuthentication(`/fpbx/extensions?_format=json`, {
                method: 'post',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Accept': '*/*'}),
                body: JSON.stringify(param),
            });
            const data = await response.json();
            console.log(data);
            return data;
        } catch (err) {
            console.log('API got an error', err);
            return Promise.reject(new Error(`API error: ${err}`));
        }
    }

    async function getTestData(url) {
        try {
            const response = await fetchWithAuthentication(url, {
                method: 'get',
                headers: new Headers({
                    'Accept': 'application/json'}),
            });
            const data = await response.json();
            console.log(data);
            return data;
            /*if (data.error) {
                console.log('Error getting data', data);
            }*/
        } catch (err) {
            console.log('API got an error', err);
            return Promise.reject(new Error(`API error: ${err}`));
        }
    }

    /**
     * Wrapper for fetch() that will attempt to add a Bearer token if present.
     *
     * If there's a valid token, or one can be obtained via a refresh token, then
     * add it to the request headers. If not, issue the request without adding an
     * Authorization header.
     *
     * @param {string} url URL to fetch.
     * @param {object} options Options for fetch().
     */
    async function fetchWithAuthentication(url, options) {
        if (!options.headers.get('Authorization')) {
            const oauth_token = await token();
            if (oauth_token) {
                options.headers.append('Authorization', `Bearer ${oauth_token.access_token}`);
            }
        }

        return fetch(`${config.base}${url}`, options);
    }

    async function testFetchWithAuthentication(url, options) {
        if (!options.headers.get('Authorization')) {
            const oauth_token = await token();
            if (oauth_token) {
                console.log('using token', oauth_token);
                options.headers.append('Authorization', `Bearer ${oauth_token.access_token}`);
            }
        }

        return fetch(`${config.base}${url}`, options);
    }

    /**
     * Get the current OAuth token if there is one.
     *
     * Get the OAuth token form sessionStorage, and refresh it if necessary using
     * the included refresh_token.
     *
     * @returns {Promise}
     *   Returns a Promise that resolves to the current token, or false.
     */
    async function token() {
        let token = sessionStorage.getItem(config.token_name) !== null
            ? JSON.parse(sessionStorage.getItem(config.token_name))
            : false;

        if (!token) {
            token = sessionStorage.getItem("access_token");
            if(token === null){
                return Promise.reject(false);
            }
            token = {"access_token":token}
            return Promise.resolve(token);
        }

        // const {expires_at, refresh_token} = token;
        // if (expires_at - config.expire_margin < Date.now() / 1000) {
        //     return Promise.reject(false);
        // }
        return Promise.resolve(token);
    };

    /**
     * Request a new token using a refresh_token.
     *
     * This function is smart about reusing requests for a refresh token. So it is
     * safe to call it multiple times in succession without having to worry about
     * wether a previous request is still processing.
     */
    function refreshToken(refresh_token) {
        console.log("getting refresh token");
        if (refreshPromises[refresh_token]) {
            return refreshPromises[refresh_token];
        }

        // Note that the data in the request is different when getting a new token
        // via a refresh_token. grant_type = refresh_token, and do NOT include the
        // scope parameter in the request as it'll cause issues if you do.
        let formData = new FormData();
        formData.append('grant_type', 'refresh_token');
        formData.append('client_id', config.client_id);
        formData.append('client_secret', config.client_secret);
        formData.append('refresh_token', refresh_token);

        return (refreshPromises[refresh_token] = fetch(`${config.base}/oauth/token`, {
                method: 'post',
                headers: new Headers({
                    'Accept': 'application/json'}),
                body: formData,
            })
                .then(function (response) {
                    return response.json();
                })
                .then((data) => {
                    delete refreshPromises[refresh_token];

                    if (data.error) {
                        console.log('Error refreshing token', data);
                        return false;
                    }
                    return saveToken(data);
                })
                .catch(err => {
                    delete refreshPromises[refresh_token];
                    console.log('API got an error', err)
                    return Promise.reject(err);
                })
        );
    }

    /**
     * Store an OAuth token retrieved from Drupal in sessionStorage.
     *
     * @param {object} data
     * @returns {object}
     *   Returns the token with an additional expires_at property added.
     */
    function saveToken(data) {
        let token = Object.assign({}, data); // Make a copy of data object.
        token.date = Math.floor(Date.now() / 1000);
        token.expires_at = token.date + token.expires_in;
        sessionStorage.setItem(config.token_name, JSON.stringify(token));
        return token;
    }

    /**
     * Check if the current user is logged in or not.
     *
     * @returns {Promise}
     */
    async function isLoggedIn() {
        const oauth_token = await token();
        if (oauth_token) {
            return Promise.resolve(true);
        }
        return Promise.reject(false);
        ;
    };

    /**
     * Run a query to /oauth/debug and output the results to the console.
     */
    function debug() {
        const headers = new Headers({
            Accept: 'application/vnd.api+json',
        });

        fetchWithAuthentication('/oauth/debug?_format=json', {headers})
            .then((response) => response.json())
            .then((data) => {
                console.log('debug', data);
            });
    }

    return {
        debug,
        login,
        logout,
        isLoggedIn,
        fetchWithAuthentication,
        token,
        refreshToken,
        changePhone,
        getPhones,
        getData,
        getCRMData,
        getTestData,
        getUserExtension
    };
}
