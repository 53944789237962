import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Component, useState, useEffect} from "react";
import React from "react";
import {useHistory} from "react-router-dom";
import Example from "./forms/Example";
import Webphone from "../Webphone";
import LoginForm from "./forms/LoginForm";
import Dialpad from "./phone-routes/Dialpad";
import History from "./phone-routes/History";
import Contacts from "./phone-routes/Contacts";
import BottomMenu from "./BottomMenu";
import Settings from "./phone-routes/Settings";
import DialpadIcon from '@mui/icons-material/Dialpad';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactsIcon from '@mui/icons-material/Contacts';



const AppRouter = () => {
        const history = useHistory();
        const [menuOpt,setOpt] = useState("Dialpad");
        const [inco, setInco] = useState(false);
        const [phoneStatus, setStatus] = useState("Idle");
        const [audioInputs, setInputs] = useState(undefined);
        const [videoDevices, setVideo] = useState(undefined);
        const audio = new window.Audio();
        audio.autoplay = true;
        const subRouteList = [{comp: History, name:'History', img: HistoryIcon},
            {comp: Dialpad, name:'Dialpad', img: DialpadIcon},
            {comp: Contacts, name:'Contacts', img:ContactsIcon},
            {comp: Settings, name:'Settings', img: SettingsIcon},
        ]
        const nameToLowerCase = (name) => {
            return name.toLowerCase().replace(" ","-")
        }
    useEffect(async () => {
        try {
            var localUa = Webphone.newUa;
            if(localUa){
                localUa.on('newRTCSession', (data) => {
                    console.log('New RTC Session')
                    var session = data.session
                    if (session.direction === "incoming") {
                        setOpt("Dialpad");
                    }
                })
            }
            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioInputs = devices.filter(device => device.kind === 'audioinput');
            setInputs(audioInputs);
            console.log(audioInputs);
            const videos = devices.filter(device => device.kind === 'videoinput');
            console.log(videos);
            setVideo(videos);
            deviceType();
        } catch (e) {
            console.log(e);
        }
    }, []);


    const deviceType = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            var screen = document.getElementsByClassName("container-center-horizontal basic-screen")[0];
            screen.requestFullscreen();
        }
        return "desktop";
    };

        return (
            <Router>
                    <Route exact path="/">
                            <LoginForm/>
                    </Route>
                    <><Switch>
                        <Route exact path="/access/:token/:show_alert?">
                                <LoginForm/>
                        </Route>

                        <>
                            {(undefined != Webphone.newUa)?
                                <>
                                    <Route path="/webphone">
                                        <Dialpad menu={<BottomMenu subRouteList={subRouteList} menuOpt={menuOpt} setOpt={setOpt}/>}/>
                                    </Route>
                                </>:
                                <></>}
                        </>

                    </Switch>
                    </>
            </Router>);
}

export default AppRouter;