import Settings from "../Settings";
import "./Contacts.css";
import PhoneInput from "../../ui/PhoneInput";
import React, {useEffect, useState} from "react";
import {webphoneActions} from "../../../store/webphone-state-slice";
import {getAuthClient} from "../../../utils/auth";
import {useDispatch, useSelector} from "react-redux";
import {TwinSpin} from "react-cssfx-loading";
import Avatar from '@mui/material/Avatar';

const Contacts = (props) => {
    const [number, setNumber] = useState("");
    const auth = getAuthClient();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [currLead, setCurrLead] = useState(false);
    const leads         = useSelector(state => state.webphone.leads);

    const clean = (event) => {
        if("undefined" != number) {
            setNumber("");
        }
    }
    const handleInputChange = (event) => {
        console.log(event);
        const {name, value} = event.target;
        setNumber(value);
    };

    const contact = (data) =>{
        console.log(data)
        let fullname = `${data?.first_name??""}${ data?.last_name??""}`
        return (<div className={"contact-wrapper"}>
            <Avatar sx={{ bgcolor: "var(--bluemain)" }}>{fullname[0]}</Avatar>
            <div className={"contact-data"}>
                <span className={"name"}>{fullname}</span>
                <span className={"phone"}>{data?.phone_number}</span>
            </div>
        </div>)
    }

    useEffect(()=>{
        auth.getCRMData("/customers/getAllCustomerByUserUuid?compact=True")
            .then((result) => {
                if (result !== undefined) {
                    dispatch(webphoneActions.updateLeads(result));
                    setIsLoading(false)
                }
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false)
            });
    },[])

    return(
        <div className="webphone-contacts screen">
            <h1 className="title dh2">Contacts</h1>
            <div className="overlap-group4">
                <PhoneInput label={""} name={"number"} value={number} defaultValue={"Search Contact"} onChange={handleInputChange} clean={clean} classInput={"borderless-input dbody3 number"} classLabel={"col-start-flex dbody3 phone-input"}/>
                <div className="divider"></div>
            </div>
            <>{isLoading?<div className={"loading-spin-wrapper"}><TwinSpin/></div>:(leads?.length === 0)?<div className={"icon"}>
                <img
                    className="group-2083"
                    src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61f79b1b54c604073d3259ff/img/group-2083@2x.svg"
                />
                <p className="value dbody2">You don’t have any contacts yet</p>
                <div className="buttons zoomEffect">
                    <img
                        className="x24-2"
                        src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61f79b1b54c604073d3259ff/img/24-4@2x.svg"
                    />
                    <div className="label valign-text-middle dh4">Add new contact</div>
                </div>
            </div>:<div className={"contact-list"}>
                {leads.map(lead => {
                    return contact(lead)
                })}
            </div>}</>
        </div>)
}

export default Contacts;