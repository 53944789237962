import React from "react";

export default function DialpadGen({updateNumber,session}){
    const dialpad = ["1","2","3","4","5","6","7","8","9","*","0","#"];
    return(
        <div className="keypad">
            <div className={"overlap-group-container"}>
                {dialpad.map((key, j) => {
                    return (
                        <div className="number" onClick={(e)=>updateNumber(e,session)}>{key}</div>
                    )})}
            </div>
        </div>
    )
}