import {useHistory} from "react-router-dom";
import React from "react";

const BottomMenu = (props) => {
    const {subRouteList, menuOpt, setOpt} = props;
    let history = useHistory();
    const nameToLowerCase = (name) => {
        return name.toLowerCase().replace(" ","-")
    }
    return(<div className="bottom_menu">
        {Object.values(subRouteList).map((key, j) => {
            let selected = "filter-gray";
            if(menuOpt == key.name){
                selected = "filter-green";
            }
            let tempName = nameToLowerCase(key.name);
            let Comp = key.img
            return (
                <div className="overlap-group clickable" onClick={() => {
                    setOpt(key.name);
                    // eslint-disable-next-line no-restricted-globals
                    console.log({tempName});
                    // eslint-disable-next-line no-restricted-globals
                    history.push(`/${tempName}`);
                }}>
                    <Comp className={selected}/>
                    <div className={"dbody3" + " " + selected}>{key.name}</div>
                </div>
            )})}
    </div>);
}

export default BottomMenu;