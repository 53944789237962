import PhoneInput from "../../ui/PhoneInput";
import DialpadGen from "./DialpadGen";
import hide from "./hide.png";
import React from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import KeyboardHideOutlinedIcon from '@mui/icons-material/KeyboardHideOutlined';

export default function OnCallScreenDial({number, handleInputChange, clean, updateNumber, session, startCall, eraseNumber,setShowKeys, onCall, endCall }){
    return(
        <>
            <PhoneInput label={""} name={"number"} value={number} defaultValue={number} onChange={handleInputChange} clean={clean} classInput={"borderless-input dbody3 number"} classLabel={"col-start-flex dbody3 phone-input"}/>
            <div className="divider"/>
            <DialpadGen session={session} updateNumber={updateNumber}/>
            <div className="flex-row">
                <div className="btn_hide" onClick={()=>setShowKeys(false)}>
                    <KeyboardHideOutlinedIcon className={"img-px30"}/>
                    <div className="dbody3 filter-gray">Hide</div>
                </div>
                <>
                    {false == onCall?
                        <div className="btn" onClick={()=>{startCall()}}>
                            <img
                                className={"answer"}
                                src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61f29047e985439d08b55d34/img/vector-48@2x.svg"
                            />
                        </div>
                        : <div className="btn hang-color" style={{width: "50%!important"}} onClick={()=>{
                            console.log("Dial")
                            endCall(session)}}>
                            <LocalPhoneIcon className="answer" style={{
                                transform: "rotate(134deg)"}}/>
                        </div>
                    }</>
                <div className="btn_delete_grouped" onClick={eraseNumber}>
                    <img
                        src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61e937773964d2281c0590df/img/24-3@2x.svg"
                    />
                </div>
            </div>
        </>
    )
}