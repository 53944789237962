import React, {useState} from "react";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import VolumeSlider from "../../ui/VolumeSlider";
import "./TopBarStatus.css";

export default function TopBarStatus({audio = undefined}){
    const [showVolumeSlider, setShowSlider] = useState(false);
    return(
        <div className="webphone_status">
            <div className="status">
                <div className="statusIco">
                    <img
                        src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61e937773964d2281c0590df/img/group-1881@2x.svg"
                    />
                </div>
                <div className="text-status dbody3">{sessionStorage.getItem('ext')} (Connected)</div>
            </div>
            {showVolumeSlider && audio !== undefined?
                <div className={'top-bar'}>
                    <ClickAwayListener onClickAway={()=> {
                        setShowSlider(!showVolumeSlider)
                    }}>
                        <div style={{width:"-webkit-fill-available"}}>
                            <VolumeSlider val={audio}/>
                        </div>
                    </ClickAwayListener>
                </div>
                :<></>}
            <img
                src="https://anima-uploads.s3.amazonaws.com/projects/61828a5312c01fb2008ac24d/releases/61e937773964d2281c0590df/img/24-6@2x.svg"
                onClick={()=>{setShowSlider(!showVolumeSlider)}}
            />
        </div>
    )
}