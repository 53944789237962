import React from "react";

const Button = ({children, ...props}) => {
    const { name, classButton } = props;
    return (
        <input
            className={classButton}
            name={name}
            type="submit"
            value={name}
        />
    );
};

export default Button;